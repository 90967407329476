import useAppToast from "@/hooks/useAppToast"
import {
  Box,
  Button,
  Center,
  FormControl,
  HStack,
  Input,
  Stack,
  Text,
} from "@chakra-ui/react"
import { GetServerSidePropsContext } from "next"
import { unstable_getServerSession } from "next-auth"
import { signIn } from "next-auth/react"
import { useTranslation } from "next-i18next"
import { serverSideTranslations } from "next-i18next/serverSideTranslations"
import Link from "next/link"
import { useRouter } from "next/router"
import { useForm } from "react-hook-form"
import { authOptions } from "../api/auth/[...nextauth]"

interface IFormData {
  email: string
  password: string
}

const Login = () => {
  const { t } = useTranslation()
  const authErrors: { [key: string]: string } = {
    ValidationError: t("auth.ValidationError"),
    UnverifiedEmail: t("auth.UnverifiedEmail"),
    ApplicationError: t("auth.UnverifiedEmail"),
    default: t("general.defaultError"),
  }
  const router = useRouter()
  const { redirect } = router?.query ?? {}
  const toast = useAppToast()
  const { register, handleSubmit, formState } = useForm<IFormData>()

  const sendIsLogged = () => {
    if (!window.ReactNativeWebView) return
    window.ReactNativeWebView?.postMessage(JSON.stringify({ isLogged: true }))
  }

  const onSubmit = async (data: IFormData) => {
    const result = await signIn("credentials", {
      email: data.email.trim().toLowerCase(),
      password: data.password,
      redirect: false,
    })

    if (result?.ok) {
      router.push((redirect as string) ?? "/")
      sendIsLogged()
    } else {
      toast({
        description: result?.error
          ? authErrors[result?.error]
          : authErrors.default,
        status: "error",
      })
    }
  }

  return (
    <Center bg="primaryColor" height="100%">
      <Stack spacing={4} width="100%" mx="auto" maxW="md" py={12} px={6}>
        <Stack textAlign="center" align="center" spacing={0}>
          <Text
            fontWeight="light"
            as="h2"
            fontSize={{ base: "2xl", md: "3xl" }}
            fontFamily="GTMaru"
          >
            {t("auth.login_caption")}&nbsp;!
          </Text>
        </Stack>
        <Box rounded="lg" bg="white" boxShadow="lg" p={8} fontFamily="GTMaru">
          <Stack as="form" onSubmit={handleSubmit(onSubmit)} spacing={4}>
            <FormControl id="email">
              <Input
                required
                autoCapitalize="off"
                autoCorrect="off"
                {...register("email")}
                placeholder={t("auth.email_label")}
                _placeholder={{
                  base: { fontSize: "sm" },
                  sm: { fontSize: "md" },
                }}
                type="email"
              />
            </FormControl>
            <FormControl id="password">
              <Input
                required
                {...register("password")}
                placeholder={t("auth.password_label")}
                _placeholder={{
                  base: { fontSize: "sm" },
                  sm: { fontSize: "md" },
                }}
                type="password"
              />
            </FormControl>
            <HStack flexWrap="wrap" spacing={0}>
              <Text
                as={Link}
                href="/auth/password/forgotten"
                fontSize="sm"
                paddingRight={1}
              >
                {t("auth.forgotten_password_label")}
              </Text>
              <Text
                as={Link}
                href="/auth/password/forgotten"
                fontSize="sm"
                textDecoration="underline"
              >
                {t("global.click")}
              </Text>
            </HStack>

            <Stack spacing={10} paddingTop={4}>
              <Button
                isLoading={formState.isSubmitting}
                type="submit"
                fontWeight="light"
                variant="brand"
              >
                {t("auth.login_label")}
              </Button>
            </Stack>
            <HStack flexWrap="wrap" spacing={0}>
              <Text fontSize="sm" paddingRight={1}>
                {t("home.not_registered")}
              </Text>
              <Text display="inline" fontSize="sm" textDecoration="underline">
                <Link href="/auth/register">{t("home.register")}</Link>
              </Text>
            </HStack>
          </Stack>
        </Box>
      </Stack>
    </Center>
  )
}

export const getServerSideProps = async (
  context: GetServerSidePropsContext
) => {
  const session = await unstable_getServerSession(
    context.req,
    context.res,
    authOptions
  )

  if (session) {
    return {
      redirect: {
        destination: "/",
        permanent: false,
      },
    }
  }

  return {
    props: {
      ...(await serverSideTranslations(context.locale!)),
    },
  }
}

export default Login
